import ApiErrorHandler from "./ApiErrorHandler";
import Credentials from "./Credentials";
let $API_URL = "http://localhost:8062";
// let $IMAGE_SERVER_URL = "https://archery.ru:8101"
let $IMAGE_SERVER_URL = "http://localhost:8063";
if (window.location.origin.match(/localhost/) === null) {
    $API_URL = "https://archery.ru:8091";
    $IMAGE_SERVER_URL = "https://archery.ru:8101";
}
///$IMAGE_SERVER_URL = "https://archery.ru:8101"
export const API_URL = $API_URL;
export const IMAGE_SERVER_URL = $IMAGE_SERVER_URL;
export default class ApiRequest {
    static getQueryString(obj) {
        return new URLSearchParams(JSON.parse(JSON.stringify(obj))).toString();
    }
    // static async checkout(): Promise<boolean> {
    //   const credentials = Credentials.fromLocalStorage()
    //   const headers = new Headers({ "Content-Type": "application/json" })
    //   if (credentials) {
    //     headers.append("x-access-token", credentials.token.value)
    //   }
    //   const options: RequestInit = {
    //     method: "GET",
    //     headers,
    //   }
    //   const response = await fetch(`${API_URL}/checkout`, options)
    //   if (!response.ok) {
    //     if (response.status >= 500) {
    //       ApiErrorHandler.onServerError(response.status, "/checkout")
    //     }
    //     return false
    //   }
    //   return true
    // }
    static async json(apiMethod, httpMethod, data = null) {
        const credentials = Credentials.fromLocalStorage();
        const headers = new Headers({ "Content-Type": "application/json" });
        if (credentials) {
            headers.append("x-access-token", credentials.token.value);
        }
        const options = {
            method: httpMethod,
            headers,
        };
        if (data !== null) {
            options.body = JSON.stringify(data);
        }
        let response;
        try {
            response = await fetch(`${API_URL}${apiMethod}`, options);
        }
        catch (e) {
            console.log(e);
            ApiErrorHandler.onServerUnavailable();
        }
        if (!response.ok) {
            if (response.status >= 500) {
                ApiErrorHandler.onServerError(response.status, apiMethod, data);
            }
            await ApiErrorHandler.onBussinesLogicError(response.status, apiMethod, data, response);
        }
        // const text = await response.text()
        // console.log(apiMethod, httpMethod)
        // if (httpMethod === "PUT") {
        //   console.log(text)
        // }
        return response.json();
    }
    static async upload(apiMethod, formData) {
        const credentials = Credentials.fromLocalStorage();
        const headers = new Headers();
        if (credentials) {
            headers.append("x-access-token", credentials.token.value);
        }
        let response;
        try {
            response = await fetch(`${API_URL}${apiMethod}`, {
                method: "POST",
                body: formData,
                headers,
            });
        }
        catch (e) {
            console.log(e);
            ApiErrorHandler.onServerUnavailable();
        }
        if (!response.ok) {
            if (response.status >= 500) {
                ApiErrorHandler.onServerError(response.status, apiMethod, { NO_DEFAULT_PAYLOAD: "upload error" });
            }
            await ApiErrorHandler.onBussinesLogicError(response.status, apiMethod, { NO_DEFAULT_PAYLOAD: "upload error" }, response);
        }
        return response.json();
    }
    static async downloadAccreditaiton(apiMethod, data, filename) {
        const credentials = Credentials.fromLocalStorage();
        const headers = new Headers();
        headers.append("Content-type", "application/json");
        if (credentials) {
            headers.append("x-access-token", credentials.token.value);
        }
        let response;
        try {
            response = await fetch(`${API_URL}${apiMethod}?taskId=${data.taskId}`, {
                method: "GET",
                headers,
            });
        }
        catch (e) {
            console.log(e);
            ApiErrorHandler.onServerUnavailable();
        }
        if (response.status === 202) {
            return null;
        }
        if (!response.ok) {
            if (response.status >= 500) {
                ApiErrorHandler.onServerError(response.status, apiMethod, data);
            }
            await ApiErrorHandler.onBussinesLogicError(response.status, apiMethod, data, response);
        }
        const responseBlob = await response.blob();
        const docLink = document.createElement("a");
        const docUrl = window.URL.createObjectURL(responseBlob);
        docLink.href = docUrl;
        docLink.download = filename;
        document.body.append(docLink);
        docLink.click();
        docLink.remove();
        window.URL.revokeObjectURL(docUrl);
        return response;
    }
    static async download(apiMethod, data, filename) {
        const credentials = Credentials.fromLocalStorage();
        const headers = new Headers();
        headers.append("Content-type", "application/json");
        if (credentials) {
            headers.append("x-access-token", credentials.token.value);
        }
        let response;
        try {
            response = await fetch(`${API_URL}${apiMethod}`, {
                method: "POST",
                body: JSON.stringify(data),
                headers,
            });
        }
        catch (e) {
            console.log(e);
            ApiErrorHandler.onServerUnavailable();
        }
        if (!response.ok) {
            if (response.status >= 500) {
                ApiErrorHandler.onServerError(response.status, apiMethod, data);
            }
            await ApiErrorHandler.onBussinesLogicError(response.status, apiMethod, data, response);
        }
        const responseBlob = await response.blob();
        const docLink = document.createElement("a");
        const docUrl = window.URL.createObjectURL(responseBlob);
        docLink.href = docUrl;
        docLink.download = filename;
        document.body.append(docLink);
        docLink.click();
        docLink.remove();
        window.URL.revokeObjectURL(docUrl);
        return response;
    }
}
