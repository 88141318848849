var EventModule_1;
import { __decorate } from "tslib";
import ApiRequest from "../../shared/ApiRequest";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
let EventModule = EventModule_1 = class EventModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.apiRoute = {
            ROOT_FACTORY: (qs) => `/events?${qs}`,
            ROOT_ID_FACTORY: (id) => `/events/${id}`,
            ROOT_ID_PUBLIC_FACTORY: (id) => `/public/events/${id}`,
            ROOT: `/events`,
            AVATAR: "/events/avatar",
            PARTICIPANTS: "/events/participants",
            PARTICIPANTS_BATCH: "/events/participants-batch",
            PARTICIPANTS_OWN: "/events/participants-own",
            ACCREDITATION: "/events/accreditation",
            IANSEO_EXPORT: "/events/ianseo-export",
            IANSEO_EXPORT_GROUPED: "/events/ianseo-export-grouped",
            UPLOAD_IANSEO_PROTOCOL: "/events/ianseo-protocol",
            EVENT_RESULTS_REPORT_FACTORY: (id) => `/events/results/${id}`,
            EVENT_RESULTS_REPORT: "/events/results",
        };
        this.$eventsTable = {};
        this.$events = [];
        this.$orderedAccreditationTaskId = null;
        this.UPDATE_EVENT_DIRECTLY_MUTATION = "updateEventDirectlyMutation";
        this.APPEND_EVENT_MUTATION = "appendEventMutation";
        this.DELETE_EVENT_BY_ID_MUTATION = "deleteEventByIdMutation";
        this.SET_EVENTS_MUTATION = "setEventsMutation";
        this.APPEND_PARTICIPANTS_MUTATION = "appendParticipantsMutation";
        this.REMOVE_PARTICIPANTS_MUTATION = "removeParticipantsMutation";
        this.SET_ORDERED_ACCREDITATION_TASK_ID_MUTATION = "setOrderedAccreditationTaskId";
    }
    get events() {
        return this.$events;
    }
    get eventsTable() {
        return this.$eventsTable;
    }
    get UNLIMITED_PARTICIPANTS_VALUE() {
        return 99999;
    }
    get orderedAccreditationTaskId() {
        return this.$orderedAccreditationTaskId;
    }
    updateEventDirectlyMutation(data) {
        const { event } = data;
        this.$eventsTable[event.id].name = event.name;
        this.$eventsTable[event.id].typeId = event.typeId;
        this.$eventsTable[event.id].addressId = event.addressId;
        this.$eventsTable[event.id].startDate = event.startDate;
        this.$eventsTable[event.id].endDate = event.endDate;
        this.$eventsTable[event.id].registrationEndDate = event.registrationEndDate;
        this.$eventsTable[event.id].isOpenForRegistration = event.isOpenForRegistration;
        this.$eventsTable[event.id].participationFee = event.participationFee;
        this.$eventsTable[event.id].logo = event.logo;
        this.$eventsTable[event.id].isPremoderated = event.isPremoderated;
        if (event.categories !== undefined) {
            this.$eventsTable[event.id].categories = event.categories;
        }
        if (event.type !== undefined) {
            this.$eventsTable[event.id].type = event.type;
        }
        if (event.disciplines !== undefined) {
            this.$eventsTable[event.id].disciplines = event.disciplines;
        }
        if (event.participants !== undefined) {
            this.$eventsTable[event.id].participants = event.participants;
        }
        if (event.limits) {
            this.$eventsTable[event.id].limits = event.limits;
        }
        if (event.transportations) {
            this.$eventsTable[event.id].transportations.splice(0);
            event.transportations.map((t) => this.$eventsTable[event.id].transportations.push(t));
        }
    }
    appendEventMutation(data) {
        const { event } = data;
        this.$events.push(event);
        this.$eventsTable[event.id] = event;
    }
    deleteEventByIdMutation(data) {
        const { id } = data;
        delete this.$eventsTable[id];
        const eventIndex = this.$events.findIndex((item) => item.id === id);
        if (eventIndex !== -1) {
            this.$events.splice(eventIndex, 1);
        }
    }
    setEventsMutation(data) {
        const { events } = data;
        this.$events = events;
        this.$eventsTable = {};
        for (let event of this.$events) {
            if (Object.prototype.hasOwnProperty.call(this.$eventsTable, event.id) === false) {
                this.$eventsTable[event.id] = event;
            }
        }
    }
    appendParticipantsMutation(data) {
        if (!this.$eventsTable[data.eventId])
            return;
        for (const participant of data.participants) {
            this.$eventsTable[data.eventId].participants.push(participant);
        }
    }
    removeParticipantsMutation(data) {
        if (!this.$eventsTable[data.eventId])
            return;
        const participantsTable = new Map();
        this.$eventsTable[data.eventId].participants.map((participant, index) => {
            participantsTable.set(participant.id, index);
        });
        for (const id of data.participantsIds) {
            if (participantsTable.has(id)) {
                const index = participantsTable.get(id);
                this.$eventsTable[data.eventId].participants.splice(index, 1);
            }
        }
    }
    setOrderedAccreditationTaskId(data) {
        this.$orderedAccreditationTaskId = data.taskId;
    }
    async getEvents(data = {}) {
        const qs = ApiRequest.getQueryString(data);
        const response = await ApiRequest.json(this.apiRoute.ROOT_FACTORY(qs), "GET");
        this.context.commit(this.SET_EVENTS_MUTATION, { events: response });
        return response;
    }
    async loadEvent(data) {
        return ApiRequest.json(this.apiRoute.ROOT_ID_FACTORY(data.id), "GET");
    }
    async loadEventPublic(data) {
        return ApiRequest.json(this.apiRoute.ROOT_ID_PUBLIC_FACTORY(data.id), "GET");
    }
    async uploadAvatar(data) {
        return ApiRequest.upload(this.apiRoute.AVATAR, data.fd);
    }
    async addEvent(data) {
        const response = await ApiRequest.json(this.apiRoute.ROOT, "POST", data);
        this.context.commit(this.APPEND_EVENT_MUTATION, { event: response });
        return response;
    }
    async updateEvent(data) {
        const response = await ApiRequest.json(this.apiRoute.ROOT_ID_FACTORY(data.eventId), "PUT", {
            event: data.event,
        });
        this.context.commit(this.UPDATE_EVENT_DIRECTLY_MUTATION, { event: response });
        return response;
    }
    async deleteEvent(data) {
        const response = await ApiRequest.json(this.apiRoute.ROOT_ID_FACTORY(data.id), "DELETE");
        this.context.commit(this.DELETE_EVENT_BY_ID_MUTATION, data);
        return response;
    }
    async addParticipants(data) {
        const response = await ApiRequest.json(this.apiRoute.PARTICIPANTS, "POST", {
            participants: data.participants,
        });
        this.context.commit(this.APPEND_PARTICIPANTS_MUTATION, { eventId: data.eventId, participants: response });
        return response;
    }
    async declareParticipants(data) {
        return ApiRequest.json(this.apiRoute.PARTICIPANTS_BATCH, "POST", {
            participants: data.participants,
        });
    }
    async applyParticipation(data) {
        return ApiRequest.json(this.apiRoute.PARTICIPANTS_OWN, "POST", {
            participant: data.participant,
        });
    }
    async deleteParticipants(data) {
        const response = await ApiRequest.json(this.apiRoute.PARTICIPANTS, "DELETE", {
            eventId: data.eventId,
            participantsIds: data.participantsIds,
        });
        this.context.commit(this.REMOVE_PARTICIPANTS_MUTATION, data);
        return response;
    }
    async recallParticipants(data) {
        return ApiRequest.json(this.apiRoute.PARTICIPANTS_BATCH, "DELETE", {
            eventId: data.eventId,
            participantsIds: data.participantsIds,
        });
    }
    async recallParticipation(data) {
        return ApiRequest.json(this.apiRoute.PARTICIPANTS_OWN, "DELETE", {
            eventId: data.eventId,
            participantId: data.participantId,
        });
    }
    async createAccreditation(data) {
        if (this.context.getters[EventModule_1.types.getters.ORDERED_ACCREDITATION_TASK_ID])
            return;
        const res = await ApiRequest.json(this.apiRoute.ACCREDITATION, "POST", data.template);
        this.context.commit(this.SET_ORDERED_ACCREDITATION_TASK_ID_MUTATION, res);
        return;
    }
    async downloadAccreditation(data) {
        const res = await ApiRequest.downloadAccreditaiton(this.apiRoute.ACCREDITATION, { taskId: data.taskId }, `Аккредитация_${data.eventName}.pdf`);
        if (res === null) {
            return false;
        }
        this.context.commit(this.SET_ORDERED_ACCREDITATION_TASK_ID_MUTATION, { taskId: null });
        return true;
    }
    async ianseoExport(data) {
        const response = await ApiRequest.download(this.apiRoute.IANSEO_EXPORT, data, `ianseo_${this.$eventsTable[data.id].name}.csv`);
        return response;
    }
    async ianseoExportGrouped(data) {
        await ApiRequest.download(this.apiRoute.IANSEO_EXPORT_GROUPED, data, `ianseo_${this.$eventsTable[data.eventId].name}.csv`);
    }
    async uploadIanseoProtocol(data) {
        return ApiRequest.upload(this.apiRoute.UPLOAD_IANSEO_PROTOCOL, data.fd);
    }
    async downloadResultsReportForEvent(data) {
        return ApiRequest.download(this.apiRoute.EVENT_RESULTS_REPORT_FACTORY(data.eventId), data, `Результаты_${this.$eventsTable[data.eventId].name}.xlsx`);
    }
    async downloadResultsReport(request) {
        return ApiRequest.download(this.apiRoute.EVENT_RESULTS_REPORT, request, `Результаты_${request.dateDiapasone.from}_${request.dateDiapasone.to}.xlsx`);
    }
    async removeResults(data) {
        return ApiRequest.json(this.apiRoute.EVENT_RESULTS_REPORT_FACTORY(data.eventId), "DELETE");
    }
};
EventModule.types = {
    getters: {
        EVENTS: "Event/events",
        EVENTS_TABLE: "Event/eventsTable",
        UNLIMITED_PARTICIPANTS_VALUE: "Event/UNLIMITED_PARTICIPANTS_VALUE",
        ORDERED_ACCREDITATION_TASK_ID: "Event/orderedAccreditationTaskId",
    },
    actions: {
        GET_EVENTS: "Event/getEvents",
        LOAD_EVENT: "Event/loadEvent",
        LOAD_EVENT_PUBLIC: "Event/loadEventPublic",
        UPLOAD_AVATAR: "Event/uploadAvatar",
        ADD_EVENT: "Event/addEvent",
        UPDATE_EVENT: "Event/updateEvent",
        DELETE_EVENT: "Event/deleteEvent",
        ADD_PARTICIPANTS: "Event/addParticipants",
        DECLARE_PARTICIPANTS: "Event/declareParticipants",
        APPLY_PARTICIPATION: "Event/applyParticipation",
        DELETE_PARTICIPANTS: "Event/deleteParticipants",
        RECALL_PARTICIPANTS: "Event/recallParticipants",
        RECALL_PARTICIPATION: "Event/recallParticipation",
        CREATE_ACCREDITATION: "Event/createAccreditation",
        DOWNLOAD_ACCREDITATION: "Event/downloadAccreditation",
        IANSEO_EXPORT: "Event/ianseoExport",
        IANSEO_EXPORT_GROUPED: "Event/ianseoExportGrouped",
        UPLOAD_IANSEO_PROTOCOL: "Event/uploadIanseoProtocol",
        DOWNLOAD_RESULTS_REPORT_FOR_EVENT: "Event/downloadResultsReportForEvent",
        DOWNLOAD_RESULTS_REPORT: "Event/downloadResultsReport",
        REMOVE_RESULTS: "Event/removeResults",
    },
};
__decorate([
    Mutation
], EventModule.prototype, "updateEventDirectlyMutation", null);
__decorate([
    Mutation
], EventModule.prototype, "appendEventMutation", null);
__decorate([
    Mutation
], EventModule.prototype, "deleteEventByIdMutation", null);
__decorate([
    Mutation
], EventModule.prototype, "setEventsMutation", null);
__decorate([
    Mutation
], EventModule.prototype, "appendParticipantsMutation", null);
__decorate([
    Mutation
], EventModule.prototype, "removeParticipantsMutation", null);
__decorate([
    Mutation
], EventModule.prototype, "setOrderedAccreditationTaskId", null);
__decorate([
    Action
], EventModule.prototype, "getEvents", null);
__decorate([
    Action
], EventModule.prototype, "loadEvent", null);
__decorate([
    Action
], EventModule.prototype, "loadEventPublic", null);
__decorate([
    Action
], EventModule.prototype, "uploadAvatar", null);
__decorate([
    Action
], EventModule.prototype, "addEvent", null);
__decorate([
    Action
], EventModule.prototype, "updateEvent", null);
__decorate([
    Action
], EventModule.prototype, "deleteEvent", null);
__decorate([
    Action
], EventModule.prototype, "addParticipants", null);
__decorate([
    Action
], EventModule.prototype, "declareParticipants", null);
__decorate([
    Action
], EventModule.prototype, "applyParticipation", null);
__decorate([
    Action
], EventModule.prototype, "deleteParticipants", null);
__decorate([
    Action
], EventModule.prototype, "recallParticipants", null);
__decorate([
    Action
], EventModule.prototype, "recallParticipation", null);
__decorate([
    Action
], EventModule.prototype, "createAccreditation", null);
__decorate([
    Action
], EventModule.prototype, "downloadAccreditation", null);
__decorate([
    Action
], EventModule.prototype, "ianseoExport", null);
__decorate([
    Action
], EventModule.prototype, "ianseoExportGrouped", null);
__decorate([
    Action
], EventModule.prototype, "uploadIanseoProtocol", null);
__decorate([
    Action
], EventModule.prototype, "downloadResultsReportForEvent", null);
__decorate([
    Action
], EventModule.prototype, "downloadResultsReport", null);
__decorate([
    Action
], EventModule.prototype, "removeResults", null);
EventModule = EventModule_1 = __decorate([
    Module({ namespaced: true })
], EventModule);
export default EventModule;
